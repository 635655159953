import $ from 'jquery';
import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import initModulesInScope from '@elements/init-modules-in-scope';
import {clearAll, showNotification} from '@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from "@elements/fetch"
import formDataEntries from 'form-data-entries';
import {isParsleyForm, isValid, loadParsley} from "@elements/parsley-bootstrap-validation";
import {responseTracking} from "@elements/tracking";
import {isOverlayOpen, showOverlay} from "./toggle-offscreen";
import {debounce} from "debounce";

const configName = '_cartConfig';

let pendingRequest,
    $listLoading,
    $listResult,
    $count,
    $cartDropdown;

export function initInScope($scope, {onAdd, onAddSucceed, onAddFailed} = {}) {
    $cartDropdown = $('.js-cart-dropdown');
    $listLoading = $('.js-cart__list-loading');
    $listResult = $('.js-cart__list-result');
    $count = $('.js-num-of-products');
    let $cartInfoPopup = $scope.find('.js-cart-info');
    let $cartInfoPopupBtn = $scope.find('.js-cart-info__close');
    let $cartForm = $scope.find('.js-cart__form');
    let $cartRemove = $scope.find('.js-table--cart-delete');
    let $cartAdd = $scope.find('.js-add-to-cart');
    let $cartAddUpselling = $scope.find('.js-add-to-cart-upselling');
    let $cartAmount = $scope.find('.js-cart__amount');
    let $cartLoading = $('.js-cart__loading');
    let $cartResult = $('.js-cart__result');
    let $addToCartResult = $('.js-cart__add-to-cart-result');
    // let $cartNotifications = $('.js-cart__notifications');

    if ($cartForm && $cartForm.length) {

        let formTrigger = $cartForm.data('trigger') || 'submit';

        $cartForm.each(function () {
            let $thisForm = $(this);
            let addToCart = $thisForm.hasClass('js-cart__form--add');
            let upsellingModalForm = $thisForm.hasClass('js-cart__form__upselling--add');
            let formInOverlay = $thisForm.hasClass('js-cart__form--overlay');
            let modalSelectorSm = $thisForm.data('ajax-modal-sm');
            let modalSelectorMd = $thisForm.data('ajax-modal-md');
            let modalSelectorLg = $thisForm.data('ajax-modal-lg');
            let modalSelectorXl = $thisForm.data('ajax-modal-xl');
            let modals = {
                'sm': $(modalSelectorSm),
                'md': $(modalSelectorMd),
                'lg': $(modalSelectorLg),
                'xl': $(modalSelectorXl)
            };

            if (addToCart) {
                requireConfigValues(['cartAddUrl'], configName);
            } else {
                requireConfigValues(['cartEditUrl'], configName);
            }

            $(this).on(formTrigger, function (evt) {
                evt.preventDefault();
                loadParsley().then(function () {
                    if (isParsleyForm($thisForm) && !isValid($thisForm)) {
                        return;
                    }

                    const data = new URLSearchParams(formDataEntries($thisForm[0]));

                    $cartLoading.attr('hidden', null);
                    $listLoading.attr('hidden', null);

                    clearAll({
                        // $container: $cartNotifications
                    });

                    call(onAdd);

                    let url = addToCart ? getConfigValue('cartAddUrl', configName) : getConfigValue('cartEditUrl', configName);

                    // use url from the clicked button if it has a data-action attribute
                    if(evt !== undefined && evt.originalEvent !== undefined && evt.originalEvent.submitter){
                        let submitBtn = $(evt.originalEvent.submitter);
                        if(submitBtn.data('action')) {
                            url = submitBtn.data('action');
                        }
                    }

                    let request = fetch(url, {
                        method: 'post',
                        body: data,
                    });

                    let $notification = showNotification(request);

                    responseTracking(request);

                    request.then(response => response.json())
                        .then(result => {

                            if (result.success) {
                                // the cart will be replaced after changes
                                let content = result.content || result.html;
                                if (content && $cartResult.length) {
                                    $cartResult.empty().append(result.content);
                                    // $cartLoading.attr('hidden', 'hidden');
                                    // $cartResult.attr('hidden', null);

                                    initModulesInScope($cartResult);
                                }

                                // this is usually the add-to-cart-form
                                let adToCartContent = result.addToCartForm;
                                if (adToCartContent && $addToCartResult.length) {
                                    $addToCartResult.empty().append(result.addToCartForm);

                                    initModulesInScope($addToCartResult);
                                }

                                setListContent($listResult, $listLoading, result.listContent);
                                if (typeof result.count !== "undefined") {
                                    setCount($count, result.count);
                                }

                                //reload cart if no items
                                if ($cartResult.length) {
                                    if(result.count === 0){
                                        location.reload();
                                    }
                                }

                                // product has been added to cart, so show the dropdown
                                // also show it, if this was a change from the cart-overlay (product removed)
                                if ( !upsellingModalForm && (addToCart || formInOverlay) && matchMedia('(min-width: 768px)').matches) {
                                    // prevent bodyclickhandler from handling this click
                                    // evt.stopImmediatePropagation();
                                    //showDropdown();
                                }

                                if(upsellingModalForm){
                                    let $modal = $thisForm.closest('.modal');
                                    let $currentItem = $thisForm.closest('.js-upselling-modal__item');
                                    $currentItem.addClass('d-none');
                                    let $items = $modal.find('.js-cart__form__upselling--add.js-cart__form__upselling--not-submitted');
                                    //hide modal if all items have been added to cart
                                    if($items.length === 1){
                                        $modal.modal('hide');
                                    }
                                    $thisForm.removeClass('js-cart__form__upselling--not-submitted');
                                }

                                call(onAddSucceed);

                                if (result.modalContent) {
                                    let $modal = $('#ajaxModalXl');
                                    if ($modal.length) {
                                        let $modalContent = $modal.find('.js-ajax-modal-content');
                                        $modalContent.html(result.modalContent);
                                        initModulesInScope($modalContent);
                                        $modal.modal('show');
                                    }
                                }

                                setTimeout(function () {
                                    initModulesInScope($('.js-alert-notification'));
                                }, 500);

                                $(function () {
                                    $('[data-toggle="popover"]').popover();
                                });

                            } else {
                                if (result.modalContent) {
                                    let $modal = modals['lg'];

                                    if ($modal.length) {
                                        let $modalContent = $modal.find('.js-ajax-modal-content');
                                        $modalContent.html(result.modalContent);
                                        initModulesInScope($modalContent);
                                        // close other modals
                                        $('.modal').modal('hide');
                                        $modal.modal('show');
                                    }
                                } else if (result.pdsAjaxInfo) {
                                    let $pdsForm = $('.js-pds-lazy-data');
                                    $pdsForm.html(result.pdsAjaxInfo);
                                    initModulesInScope($pdsForm);
                                }

                                call(onAddFailed);
                            }
                        }).catch(e => {
                        console.error(e);
                        call(onAddFailed);
                    }).finally(e => {
                        $cartLoading.attr('hidden', 'hidden');
                        //   $cartResult.attr('hidden', 'hidden');
                        $listLoading.attr('hidden', 'hidden');
                        $listResult.attr('hidden', false);
                    });
                });
            });

        });


        // remove product from cart
        $cartRemove.on('click', function (evt) {
            evt.preventDefault();
            let $form = $(this).closest('.js-cart__form');
            $form.find('.js-cart__amount').val('0');
            $form.trigger('submit');
        });


        // add product from upselling modal to cart
        $cartAddUpselling.on('click', function (evt) {
            evt.preventDefault();
            $(this).closest('.js-cart__form').trigger('submit');
        });


        // add product to cart
        $cartAdd.on('click', function (evt) {
            evt.preventDefault();

            let $sizeInput = $('.js-shoe-size__input');
            let $sizeInputChecked = $('.js-shoe-size__input:checked');
            let isGrid = _config.productGrid;
            let isPds = _config.productDetail;

            if(($sizeInput.length > 0 && isGrid) || ($sizeInput.length > 0 && isPds)) {
                //FIRED ON PDS
                if($sizeInputChecked.length > 0) {
                    $(this).closest('.js-cart__form').trigger('submit');
                } else {
                    evt.stopImmediatePropagation();
                    let $overlay = $('#sizeOverlay');
                    if (isOverlayOpen($overlay)){
                        // submit to show the same size missing error if the overlay is already open
                        $(this).closest('.js-cart__form').trigger('submit');
                    } else {
                        showOverlay($overlay);
                    }
                }
            } else {
                // Fired on Contentpages
                $(this).closest('.js-cart__form').trigger('submit');
            }
        });

        $cartAmount.on('change numberSpinner.changed', debounce(function (evt) {
            evt.preventDefault();
            let $form = $(this).closest('.js-cart__form');
            $form.trigger('submit');
        }, 800));
    }


    // cart info popup
    $cartInfoPopupBtn.on('click', function (evt) {
        evt.preventDefault();
        $(this).closest($cartInfoPopup).fadeOut(250);
    });
}

function setCount($element, count) {
    if (count) {
        $element.attr('hidden', null);
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}

function setListContent($listResult, $listLoading, content) {
    if (content) {
        $listResult.empty().append(content);
        $listLoading.attr('hidden', 'hidden');
        $listResult.attr('hidden', null);

        initModulesInScope($listResult);
    } else {
        $listLoading.attr('hidden', 'hidden');
        $listResult.attr('hidden', 'hidden');
    }
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}

// cart overlay/dropdown functions
export function showDropdown() {
    $cartDropdown.addClass('is-open');
    $('body').on('click', bodyClickHandler);
}

export function hideDropdown() {
    $cartDropdown.removeClass('is-open');
    $('body').off('click', bodyClickHandler);
}

function bodyClickHandler (evt) {
    if ($(evt.target).closest('.js-cart-dropdown').length < 1) {
        // evt.preventDefault();
        hideDropdown();
    }
}